<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" inline :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="简报日期" prop="date">
        <ys-date-picker v-model="form.date" style="width: 100%;" placeholder="简报日期"></ys-date-picker>
      </FormItem>
      <FormItem label="发送机构" prop="serOrgId">
        <Input v-model="form.serOrgName" readonly placeholder="发送机构">
          <Button slot="append" @click="choiceOrg('serOrg')">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="接收机构" prop="insOrgId">
        <Input v-model="form.insOrgName" readonly placeholder="接收机构">
          <Button slot="append" @click="choiceOrg('insOrg')">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows:2,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
      <h1 class="h1Title">服务任务</h1>
      <Table border :columns="columns" :max-height="height-500" :data="form.checks"></Table>
      <Button type="primary" style="margin: 10px 0;" @click="choiceCheck" long icon="md-add-circle">新增服务任务</Button>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/brief/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <!-- 发送机构为服务机构。接收机构为保险公司 -->
      <org-component :limit="1" :oType="type === 'insOrg' ? 1 : 3" @on-ok="getData"></org-component>
    </ys-modal>
    <ys-modal v-model="checkFlag" :width="1200" title="选择服务任务">
      <OrgCheck :orgId="form.serOrgId" :signStartAt="form.date" :signEndAt="form.date" :insOrgId="form.insOrgId" @on-ok="getCheckData"></OrgCheck>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {briefInfo, getBriefCheckList} from "@/api/brief";
import OrgCheck from "_c/check";
import OrgComponent from "_c/org";

export default {
  name: 'projectEdit',
  components: {OrgComponent, OrgCheck},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        date: [
          {required: true, message: '请选择建保日期', trigger: 'change'}
        ],
        serOrgId: [
          {required: true, type: 'number', message: '请选择发送机构', trigger: 'change'}
        ],
        insOrgId: [
          {required: true, type: 'number', message: '请选择接受机构', trigger: 'change'}
        ],
      },
      checkFlag: false,
      orgFlag: false,
      columns: [
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 180, title: '项目名称', tooltip: true, key: 'projectName', align: 'left'},
        {width: 180, title: '保险公司', tooltip: true, key: 'insName', align: 'left'},
        {width: 130, title: '保险业务人员', tooltip: true, key: 'insUsers', align: 'left'},
        {width: 130, title: '专家组成员', tooltip: true, key: 'experts', align: 'left'},
        {
          minWidth: 150, title: '配合情况', key: 'enterpriseAccept', align: 'left', render: (h, params) => {
            return h('div', [
              h('Input', {
                props: {type: 'text', value: params.row.enterpriseAccept},
                on: {
                  'on-blur': (event) => {
                    params.row['enterpriseAccept'] = event.target.value;
                    this.form.checks[params.index] = params.row;
                  }
                }
              })
            ]);
          }
        },
        {
          title: '操作', width: 50, align: 'center', render: (h, params) => {
            return h('Poptip', {
              props: {width: 300, confirm: true, title: "确定要删除么？删除后在未保存提交前都可恢复！"},
              on: {
                'on-ok': () => {
                  this.form.checks.splice(params.index, 1)
                }
              }
            }, [
              h('Button', {
                props: {icon: 'md-trash', size: "small"},
              })
            ]);
          }
        }
      ],
      type: '',
      form: {
        id: '',
        date: '',
        insOrgId: '',
        serOrgId: '',
        serOrgName: '',
        insOrgName: '',
        checks: [],
        desc: '',
      },
    }
  },
  async mounted() {
    if (this.mid) {
      await briefInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          if (item !== 'checks') this.form[item] = res.data[item] || null
        });
      });
      await getBriefCheckList({id: this.form.id}).then(res => {
        if (res.code === 200) {
          this.form.checks = res.data.list || [];
        }
      })
    } else {
      this.form.date = new Date();
    }
  },
  methods: {
    remove() {

    },
    choiceCheck() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.checkFlag = true;
        } else {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "请选择发送机构和接受机构！"
          });
        }
      })
    },
    getCheckData(data) {
      data.forEach(item => {
        let isExist = this.form.checks.filter(checksItem => item.id === checksItem.checkId);
        let existCheck = [];
        if (isExist.length > 0) {
          existCheck.push(item.projectName);
        } else {
          item.checkId = item.id;
          item.enterpriseAccept = '';
          this.form.checks.push(item)
        }
      })
    },
    getData(data) {
      if (this.form[this.type + 'Id'] !== data[0].id) {
        this.form.checks = [];
      }
      this.form[this.type + 'Id'] = data[0].id;
      this.form[this.type + 'Name'] = data[0].iname;
    },
    choiceOrg(type) {
      this.orgFlag = true;
      this.type = type;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
